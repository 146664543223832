import { useNavigate } from '@remix-run/react';
import { captureMessage } from '@sentry/remix';
import { addMinutes, format } from 'date-fns';
import { usePopperTooltip } from 'react-popper-tooltip';
import useSWRImmutable from 'swr/immutable';

import {
  EnumsOnboardingTaskName,
  EnumsOnboardingTaskOperation,
} from '@lp-lib/api-service-client/public';

import { useEventSchedulerAnalytics } from '../../analytics/eventScheduler';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { getQueryParam } from '../../hooks/useQueryParam';
import { apiService, type InvitedUser } from '../../services/api-service';
import { type CreateEventRequest } from '../../services/api-service/event.api';
import { fromDTOGamePack, fromDTOOrganizer } from '../../utils/api-dto';
import { type EventTime } from '../../utils/date';
import {
  ConfirmCancelModalHeading,
  useAwaitFullScreenConfirmCancelModal,
} from '../ConfirmCancelModalContext';
import { QuestionMarkIcon } from '../icons/QuestionMarkIcon';
import { PurchaseMethod } from '../OneTimePurchase/type';
import { useMyOrganization, useMyOrgId } from '../Organization';
import {
  parseAnalyticsPropertiesFromOptions,
  parseUserIdsFromOptions,
} from '../Organization/OrganizerSelectGroup';
import { useUser } from '../UserContext';
import {
  createNewVenueOption,
  emptyProducer,
  EventEditor,
  type EventEditorData,
} from './Editor';

function NoOrgMessage(): JSX.Element {
  return (
    <div className='w-3/4 md:w-1/2 xl:w-1/3 h-full flex flex-col items-center justify-center gap-3 text-lg text-white text-center'>
      Scheduled events are only supported for organization members.
    </div>
  );
}

function WhatIsOnDemand(): JSX.Element {
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      placement: 'right',
      trigger: 'hover',
    });

  return (
    <div ref={setTriggerRef} className='text-icon-gray'>
      <QuestionMarkIcon className='w-5 h-5 fill-current' />
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: `w-48 h-auto rounded-md text-white text-xs px-3 py-2 bg-black border border-secondary z-40`,
          })}
        >
          Luna Park On Demand lets you play social games with your team at any
          time! Each experience has a pre-recorded host, is fully interactive
          and highly collaborative!
        </div>
      )}
    </div>
  );
}

export function OnDemandEventCreate(props: {
  backTo?: string;
  gamePackId?: string;
  eventTime?: EventTime;
  durationMin?: number;
  fromOtp?: boolean;
}): JSX.Element | null {
  const analytics = useEventSchedulerAnalytics();

  const myOrgId = useMyOrgId();
  const me = useUser();
  const navigate = useNavigate();
  const myOrg = useMyOrganization();

  const onSubmit = async (data: EventEditorData) => {
    if (!data.gamePackId) return;

    const { startDate, timezone } = data.eventTime;
    const endAt = addMinutes(startDate, data.durationMin).toISOString();

    let attendeeUids = parseUserIdsFromOptions(data.attendees);
    const invitedUsers: InvitedUser[] = [];
    for (const attendee of data.attendees) {
      if (attendee.kind === 'slack-user') {
        invitedUsers.push({
          email: attendee.user.email,
          fullName: attendee.user.fullName,
          exUserId: attendee.user.id,
        });
      }
      if (attendee.kind === undefined) {
        invitedUsers.push({
          email: attendee.value,
        });
      }
    }
    if (invitedUsers.length > 0) {
      if (myOrgId) {
        const resp = await apiService.organization.inviteOrganizers(myOrgId, {
          invitedUsers,
          webEndpoint: window.location.origin,
          sendEmail: true,
        });
        const set = new Set(attendeeUids);
        for (const o of resp.data.organizers) {
          set.add(o.uid);
        }
        attendeeUids = Array.from(set);
      } else {
        captureMessage(`create ond event with slack users but no orgId`, {
          extra: {
            uid: me.id,
          },
        });
      }
    }

    const req: CreateEventRequest = {
      orgId: myOrgId ?? undefined,
      gamePackId: data.gamePackId,
      message: data.message,
      startAt: startDate.toISOString(),
      endAt: endAt,
      timezone,
      organizerUid: me.id,
      attendees: attendeeUids,
      attendeeEmails: data.attendeeEmails,
      type: 'ond',
    };

    analytics.trackFormSubmitted({
      ...req,
      message: undefined,
      hasMessage: (data.message?.length ?? 0) > 0,
      totalAttendees: data.attendees.length + 1,
      ...parseAnalyticsPropertiesFromOptions(data.attendees),
      durationMin: data.durationMin,
      purchaseMethod: myOrg?.purchase?.gamePackIds.includes(data.gamePackId)
        ? PurchaseMethod.OneTimePurchase
        : undefined,
    });

    const resp = await apiService.event.create(req);

    if (myOrgId) {
      await apiService.onboarding.operateTask(
        EnumsOnboardingTaskName.OnboardingTaskNameScheduleOndGame,
        {
          orgId: myOrgId,
          operation:
            EnumsOnboardingTaskOperation.OnboardingTaskOperationMarkDone,
        }
      );
    }

    navigate(`/events/${resp.data.event.id}`);
  };

  const onCancel = () => {
    analytics.trackFormDismissed();
    if (props.backTo) {
      navigate(props.backTo);
    } else {
      navigate(-1);
    }
  };

  return (
    <div className='h-full flex-1 overflow-y-auto scrollbar bg-game-library bg-w-full bg-no-repeat bg-top bg-local flex justify-center'>
      {!myOrgId ? (
        <NoOrgMessage />
      ) : (
        <div className='w-full h-full'>
          <EventEditor
            title={
              <div className='flex items-center gap-2'>
                {props.fromOtp ? (
                  <>Schedule Your Event</>
                ) : (
                  <>
                    <span>Schedule Your Experience</span>
                    <WhatIsOnDemand />
                  </>
                )}
              </div>
            }
            subtitle={
              props.fromOtp
                ? 'Completing this form will send you a calendar invite for your event with instructions on how to get started. We’ll also email a calendar invite to any guests you add below.'
                : 'Schedule a time to play a Luna Park On Demand game with your colleagues. Once completed, we’ll email your guests an invite and email you instructions on how to start and edit your event.'
            }
            initialData={{
              gamePackId: props.gamePackId,
              eventTime: props.eventTime,
              durationMin: props.durationMin,
            }}
            onSubmit={onSubmit}
            onCancel={onCancel}
            eventType='ond'
            context='create'
          />
        </div>
      )}
    </div>
  );
}

function toCreateEventRequest(
  data: EventEditorData
): CreateEventRequest | null {
  if (!data.gamePackId) return null;

  const { startDate, timezone } = data.eventTime;
  const endAt = addMinutes(startDate, data.durationMin).toISOString();

  return {
    gamePackId: data.gamePackId,
    message: data.message,
    startAt: startDate.toISOString(),
    endAt: endAt,
    timezone,
    organizerUid: data.organizerUid ?? null,
    attendees: parseUserIdsFromOptions(data.attendees),
    orgId: data.orgId ?? undefined,
    orgName: data.orgName,
    organizerName: data.organizerName,
    organizerEmail: data.organizerEmail,
    attendeeEmails: data.attendeeEmails,
    venueId: data.venueOption?.value,
    hostUid: data.hostUid,
    studioId: data.studioOption?.value,
    type: 'live',
    prepMin: data.prepMin,
    producerUid: data.producerUid,
    eventTitle: data.eventTitle,
    eventFirstParagraph: data.eventFirstParagraph,
    hostShoutOut: data.hostShoutOut,
  };
}

async function makeInitialData(liveEventRequestId: string) {
  const request = (
    await apiService.event.getLiveEventRequest(liveEventRequestId)
  ).data.request;
  const studios = (await apiService.event.liveStudio()).data.studios;

  const result: Partial<EventEditorData> = {};
  result.gamePackId = request.gamePackId;
  result.organizerUid = request.requesterUid;
  result.attendees = request.attendees?.map((organizer) => ({
    kind: 'organizer',
    organizer: fromDTOOrganizer(organizer),
  }));
  result.type = 'live';
  result.durationMin = request.gamePack
    ? Math.ceil(request.gamePack.approximateDurationSeconds / 60)
    : undefined;
  result.gamePack = fromDTOGamePack(request.gamePack) || undefined;
  result.eventTime = {
    startDate: new Date(request.startAt),
    timezone: request.timezone,
  };
  result.organizer = fromDTOOrganizer(request.requester) || undefined;
  result.orgId = request.orgId;
  result.orgName = request.requester?.organization?.name;
  result.venueOption = createNewVenueOption;
  result.hostShoutOut = request.hostShoutOut;
  const studio = studios.find((studio) =>
    studio.name.toLowerCase().includes('live 2.0')
  );
  result.studioOption = studio
    ? {
        label: studio.name,
        value: studio.id,
      }
    : undefined;
  result.producerUid = emptyProducer.uid;
  return result;
}

export function LiveEventCreate(): JSX.Element | null {
  const liveEventRequestId = getQueryParam('live-event-request-id');
  const { data: initialData, isLoading } = useSWRImmutable(
    liveEventRequestId
      ? [liveEventRequestId, '/events/live-event-request']
      : null,
    async ([id]) => makeInitialData(id)
  );

  const triggerFullScreenModal = useAwaitFullScreenConfirmCancelModal();
  const navigate = useNavigate();

  const onBeforeSubmit = useLiveCallback(async (data: EventEditorData) => {
    const req = toCreateEventRequest(data);
    if (!req) return false;

    const res = await triggerFullScreenModal({
      boxDimensionsClassName: ' w-170 h-120',
      cancelBtnClassName: 'w-40 h-10',
      confirmBtnClassName: 'w-40 h-10',
      kind: 'confirm-cancel',
      prompt: (
        <div className='px-5 py-2'>
          <ConfirmCancelModalHeading>
            Verify the Event’s Details
          </ConfirmCancelModalHeading>
          <div className='mt-4 text-sms font-normal text-white'>
            <div className='flex flex-col items-start w-120 mt-10'>
              <div>This event is scheduled for</div>
              <div className='text-yellow-400'>
                {format(new Date(req.startAt), 'EEEE, MMMM do @ h:mm aa')}
              </div>
              <br />
              <div>
                Organization:{' '}
                {data.organizer?.organization?.name ?? req.orgName}
              </div>
              <div>Host: {data.host?.name}</div>
              <div>Studio: {data.studioOption?.label}</div>
              <div>Venue: {data.venueOption?.label}</div>
              <div>Prep Time: {data.prepMin ?? 0} minutes</div>
              <div>Duration: {data.durationMin ?? 0} minutes</div>
              <br />
              <div>The invite will be sent to:</div>
              <div>
                {data.organizer
                  ? `${data.organizer.firstName} ${data.organizer.lastName}`
                  : data.organizerName}{' '}
                - {data.organizer?.email ?? data.organizerEmail}
              </div>
            </div>
          </div>
        </div>
      ),
      confirmBtnLabel: 'Schedule',
      confirmBtnVariant: 'primary',
      cancelBtnLabel: 'Back',
    });
    return res.result !== 'canceled';
  });

  const onSubmit = useLiveCallback(async (data: EventEditorData) => {
    const req = toCreateEventRequest(data);
    if (!req) return;
    await apiService.event.create(req);
    navigate('../list');
  });

  if (isLoading) return null;
  return (
    <div className='flex justify-center'>
      <div className='w-full h-full'>
        <EventEditor
          title={
            <div className='flex items-center gap-2'>
              <span>Schedule a Live Game</span>
            </div>
          }
          initialData={initialData}
          subtitle='Scheduling a live game for an organization will send the org invite and create the internal calendar event.'
          onBeforeSubmit={onBeforeSubmit}
          onSubmit={onSubmit}
          onCancel={() => navigate(-1)}
          eventType='live'
          context='create'
        />
      </div>
    </div>
  );
}
